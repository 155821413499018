import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import { Link } from 'gatsby'
import Banner from '../../../components/features/Banner'
import Question from '../../../components/features/Question'
import Video from '../../../components/features/Video'
import Info from '../../../components/features/Info'
import Steps from '../../../components/features/Steps'
import SEO from '../../../components/common/SEO';
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query WorksiteScreening {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({data}) => {
    const [toggler, setToggler] = useState(false)
    const lightboxProps = {
        toggler: toggler,
        setToggler: setToggler,
        sources: ["https://www.youtube.com/watch?v=qu0MzmEU-XI"],
    }

    const bannerProps = {
        title: 'Workplace Screening',
        subtitle: 'Assess workers and guest visitors as they access your work sites using custom checklists unique to each location. Grant or deny physical access based on various criteria such as health checks (COVID-19), required PPE or even compliance data such as certificates, orientations and competencies. Log visits along with answers for robust visitor management and contact tracing.',
        featureName: 'Compliance',
        rightButtonText: 'Why Compliance Software?',
        rightButtonSrc: 'https://www.youtube.com/watch?v=qu0MzmEU-XI',
        mockupImg: '/features/screening/feature-page-header-cworksite-screening.png'
    }

    const questionProps = {
        question: 'Are your worksite access requirements outgrowing the decals and signs?',
        answer: 'With the recent pandemic, the most basic workplaces have a long list of screening criteria and it\'s become challenging to monitor and assess without a proper screening system in place. Regulations are changing by the day, quickly rendering standard checklists obsolete before they even go in to effect. There may even be requirements for reporting things like contact tracing and legal requirements - legacy systems just can\'t keep up.',
        answerBulletList: [
        ],
        imageSrc: '/features/screening/screening-sketch.jpg',
        imgAlt: 'Cartoon of man being denied worksite access due to lack of PPE'
    }

    const videoProps = {
        videoSrc: 'https://www.youtube.com/watch?v=qu0MzmEU-XI',
        videoImage: '/features/screening/screening-thumbnail.jpg',
        videoStatement: (<h2><strong>Worksite Screening</strong> feature</h2>),
    }

    const infoProps = {
        infoList: [
            {
                mockupImage: '/features/screening/feature-large-admin-screening-1.jpg',
                infoTitle: 'Create Custom Checklists for Each Location',
                infoSubtitle: 'Import from a template, copy from another location or start building a screening checklist from scratch to suit your needs. Organize sections and questions via simple drag and drop functionality and customize the acceptable responses.',
                infoBullets: [
                    {
                        name: 'Import / Copy:',
                        description: 'Import questions from our handy templates or copy questions from a previous location'
                    },
                    {
                        name: 'Non-destructive:',
                        description: 'Add or remove questions on live checklists without losing previously logged response data',
                    },
                    {
                        name: 'Preview:',
                        description: 'Unsure of changes? Hit the preview button to have a peek at the front end view prior to saving',
                    }
                ],
            },
            {
                mockupImage: '/features/screening/feature-large-admin-screening-3.jpg',
                infoTitle: 'Robust Visitor Management with History Log for Contact Tracing',
                infoSubtitle: (<p>Log all visits and responses including the reason for the visit in order to accurately trace contact in the event of an outbreak. Workers can check themselves in via contactless self-serve view and <a href="https://workerid.com" target="_blank" rel="noopener noreferrer">WorkerID</a> QR Code scanning. </p>),
                infoBullets: [
                    {
                        name: 'Screening Details:',
                        description: 'All details of the visit are logged for full audit and tracing needs.'
                    },
                    {
                        name: 'Worker Manifest:',
                        description: 'Look up who is currently on site in the event of an emergency',
                    },
                    {
                        name: 'In-Out Board:',
                        description: 'Workers sign out when leaving the site providing in-out data for reporting',
                    }
                ],
            }
        ]
    }

    const stepsProps = {
        title: 'Notifying Workers is as Easy as 1, 2, 3',
        subtitle: 'Updating your workers on all devices/platforms and tracking readership has never been easier',
        stepList: [
            {
                title: 'Scan in with WorkerID',
                subtitle: (<p>Workers quickly scan in with the <a href="https://workerid.com" target="_blank" rel="noopener noreferrer">Free WorkerID App</a> to load their profile and display their compliance data</p>),
                imageSrc: '/features/screening/screening-step-1.jpg'
            },
            {
                title: 'Answer Screening Questions',
                subtitle: 'Workers are screened with custom questions and access is granted or denied based on defined conditions',
                imageSrc: '/features/screening/screening-step-2.jpg'
            },
            {
                title: 'Monitor Worksite Access',
                subtitle: 'Visits by workers, subcontractors and guests are all logged for safekeeping and high-risk visits are avoided',
                imageSrc: '/features/screening/screening-step-3.jpg'
            }
        ]
    }

    const seo = { 
        title: 'Workhub | Free Workplace Screening Software Health Safety COVID-19 PPE',
        description: 'Use our Worksite Screening software to grant or deny access to your worksite based on criteria such as: health screenings (COVID-19), required PPE, certificates, licenses & training.',
        image: '/features/screening/social-card.jpg',
        // pathname,
        // article
    }

    const featureIndex = [
        {
          title: "Assess Workers & Visitors",
          para: "Grant or deny physical access based on various criteria such as health checks (COVID-19), required PPE and compliance data such as certificates, orientations, and competencies.",
        },
        {
          title: "Build",
          para: "Create custom checklists that are unique to your location.",
        },
        {
          title: "Import / Copy",
          para: "Import questions from templates or copy questions from a previous location.",
        },
        {
          title: "Log",
          para: "All details of the visit are logged for full audit and tracing needs.",
        },
        {
          title: "Worker Manifest",
          para: "Look up who is currently on site in the event of an emergency.",
        },
        {
          title: "In-Out Board",
          para: "Workers sign out when leaving the site providing in-out data for reporting.",
        },

      ]

    return(
        <Layout {...lightboxProps}>
                   <SEO {...seo} {...data.site}/>
            <Banner {...bannerProps} {...lightboxProps} />
            <Question {...questionProps} />
            <Video {...videoProps} {...lightboxProps} />
            <Info {...infoProps} />
            <Steps {...stepsProps} />

                   {/* FEATURE OVERVIEW */}

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid" style={{marginBottom: '60px'}}>
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>


        </Layout>
    )
}